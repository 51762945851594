<template>
  <div>
    <v-toolbar class="mb-8" elevation="0" style="background: none">
      <v-toolbar-title class="font-weight-bold">
        Registros de Fertilidade
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <primary-button
        v-if="authoritySlug === 'super-admin' || permissions.access_records"
        label="+ Adicionar"
        @callAction="addFertilityRecordFarm"/>
    </v-toolbar>

    <search-bar v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select :items="fertilityRecords.map(item => item.harvest)"
                     v-model="harvestSearch"
                     class="mr-2"
                     placeholder="Safra"
                     @input="filterByHarvest"
                     height="0"
                     returnObject
      />
    </search-bar>

    <!-- Data Table do registro de fertilidade -->
    <v-data-table
      :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      }"
      :headers="headers"
      :items="fertilityRecords"
      :search="search.text"
      no-data-text="Nenhum registro de fertilidade encontrado"
      class="px-12 elevation-1 yellow-border rounded-xl"
      sort-by="id"
      no-results-text="Nenhum registro correspondente encontrado"
    >

      <template v-slot:[`item.id`]="{ item }">
        {{ '#' + item.id }}
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimezone }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="viewItem(item)">
          mdi-information-outline
        </v-icon>

        <v-icon
          v-if="authoritySlug === 'super-admin' || permissions.access_records"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>

    <fertility-record-farm-add
      v-if="showFertilityRecordFarmAdd"
      :dialog="showFertilityRecordFarmAdd"
      :producers="producers"
      @closeDialog="reset()"
    />

    <confirm-destroy-dialog
      :title="'este registro'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import FertilityRecords from "@/domain/records/fertility-records/fertility-records";
import Producers from "@/domain/producers/producers";
import Farms from "@/domain/farms/farms";

import FertilityRecordFarmAdd from "./FertilityRecordFarmAdd.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "FertilityRecordsTable",
  components: {
    FertilityRecordFarmAdd,
    PrimaryButton,
    SearchBar,
    SimpleSelect,
    ConfirmDestroyDialog
  },
  data() {
    return {

      authority: true,
      adminId: [1, 2],
      payload: {},

      fertilityRecordService: null,
      producerService: null,
      farmService: null,
      fertilityIndexService: null,

      fertilityRecords: [],
      producers: [],
      farmDetails: {},
      recordSelected: {},

      dialog: false,
      showFertilityRecordFarmAdd: false,
      showConfirmDestroyDialog: false,
      search: {},
      harvestSearch: "",
      authoritySlug: "",
      permissions: ""
    }
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {text: "Fazenda", value: "farm.name"},
        { text: "Proprietário", value: "farm.producer.user.name" },
        {
          text: "Safra",
          value: "harvest",
          filter: value => {
            if (!this.harvestSearch) return true
            return value === this.search.harvest;
          }
        },
        {text: "Data do Registro", value: "created_at"},
        {text: "Detalhes", value: "actions", sortable: false},
      ]
    },

    isViewingAdmin() {
      return this.authority;
    }
  },

  methods: {
    getUserLogged() {
      const loader = this.$loading.show();

      LoginService
      .getUserLogged()
      .then((data) => {

        if (!this.adminId.includes(data.authority_id)) {
          this.authority = false;
        }

        loader.hide();
      })
      .catch(() => {
        location.reload();
        loader.hide();
      });
    },

    loadFertilityRecords(params) {
      this.fertilityRecords = [];

      this.fertilityRecordService.list(params).then(result => {
        result.data.map(item => {
          this.fertilityRecords.push(item);
        })
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });
    },

    loadProducers() {
      const loader          = this.$loading.show();
      this.producers        = [];

      this.producerService.all().then(result => {
        result.data.map(item => {
          this.producers.push(item);
        });
      });

      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};
      
      this.farmService.getFarmDetails(farmId).then(result => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    viewItem(item) {
      this.$router.push({
        name: 'fertility-record-details', 
        params: {farmId: item.farm_id, harvest: item.harvest}
      });
    },

    deleteItem(item) {
      this.recordSelected = {
        data : {
          'farm_id' : item.farm_id,
          'harvest' : item.harvest,
          'type'    : 'Fertilidade'
        }
      };

      this.showConfirmDestroyDialog = true;
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.fertilityRecordService
      .delete(this.recordSelected)
      .then(() => {
        loader.hide();
        this.loadFertilityRecords();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Registro de fertilidade removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Registro de fertilidade não foi removido.", {
          type: 'error'
        });
      });
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    addFertilityRecordFarm() {
      this.showFertilityRecordFarmAdd = true;
      this.loadProducers();
    },

    reset() {
      this.dialog                      = false;
      this.showFertilityRecordFarmAdd  = false;
      this.loadFertilityRecords();
    },

    filterByHarvest() {
      this.search.harvest = this.harvestSearch;
    },

    clearFilters() {
      this.harvestSearch = null;
    },
  },

  mounted() {
    this.loadFertilityRecords();
  },

  beforeMount() {
    this.getUserLogged();
    this.payload                = FertilityRecords.newData();
    this.fertilityRecordService = FertilityRecords;
    this.producerService        = Producers;
    this.farmService            = Farms;

    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  },
};
</script>

<style scoped>
  ::v-deep .col-farm .v-toolbar__content {
    padding: 0;
  }

  .custom-container {
    border: 1px solid #E9D8A6;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }
</style>

<template>
  <div>
    <fertility-records-table class="crud-data-table"></fertility-records-table>
  </div>
</template>

<script>
import FertilityRecordsTable from "./FertilityRecordsTable";

export default {
  name: "FertilityRecords",
  components: {
    FertilityRecordsTable,
  },
};
</script>

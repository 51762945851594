<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-card-title class="form-modal-header">
          <v-btn color="white" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span class="text-h5 white--text"
            >Novo Registro</span
          >
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="save">Salvar</v-btn>
        </v-card-title>
        <v-card-text class="mt-12">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="col-farm">
                <v-toolbar elevation="0" style="background: none">
                  <v-toolbar-title class="font-weight-bold">
                    Selecionar Fazenda
                  </v-toolbar-title>
                </v-toolbar>

                <v-container class="custom-container">
                  <v-row>
                    <v-col cols="12">
                      <custom-label label="Proprietário" />
                      <simple-select
                          :items="producers"
                          v-model="payload.producer_id"
                          placeholder="Produtor"
                          itemText="user.name"
                          itemValue="producer_id"
                          height="auto"
                          @input="loadFarms($event)"
                        />
                    </v-col>

                    <v-col>
                      <custom-label label="Fazenda" />
                      <simple-select
                        :items="farms"
                        :disabled="!producerSelected"
                        v-model="payload.farm_id"
                        placeholder="Fazenda"
                        itemText="name"
                        itemValue="id"
                        height="auto"
                        @input="loadFarmDetails($event)"
                      />
                    </v-col>

                    <v-col>
                      <custom-label label="Safra"/>
                      <simple-text-field placeholder="Safra"
                                        mask="harvest"
                                        :disabled="!producerSelected || payload.farm_id == null"
                                        ref="inputHarvest"
                                        v-model="farmDetails.harvestYearCalculation"/>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <p>Tamanho Fazenda</p>
                      <p v-if="farmDetails.farm_size">{{ farmDetails.farm_size + ' ha' }}</p>
                    </v-col>

                    <v-col cols="5">
                      <p>AS Responsável</p>
                      <p>{{ getAS() }}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col cols="12" md="6" class="col-index"
                v-if="fertilityIndexes.length > 0">
                <v-toolbar elevation="0" style="background: none">
                  <v-toolbar-title class="font-weight-bold">
                    Índices de Fertilidade da Fazenda
                  </v-toolbar-title>
                </v-toolbar>

                <v-container class="custom-container">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      v-for="fertilityIndex in fertilityIndexes"
                      :key="fertilityIndex.index.id"
                    >
                      <custom-label :label=" fertilityIndex.index.name" />
                      <simple-select
                        :items="grades"
                        placeholder="Selecione uma opção"
                        :itemValue="fertilityIndex.index.id.toString()"
                        @input="handleSelect(fertilityIndex.index.id, $event)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-redirect-dialog
      :message="'Registro foi criado anteriormente, deseja atualizar o registro já existente?'"
      :routeName="'fertility-record-details'"
      :routeParams="{farmId: payload.farm_id, harvest: farmDetails.harvestYearCalculation}"
      :dialog.sync="showConfirmRedirectDialog"
      @closeDialog="closeConfirmRedirectDialog"
    />
  </div>
</template>

<script>
import FertilityRecords from "@/domain/records/fertility-records/fertility-records";
import Farms from "@/domain/farms/farms";
import Contracts from "@/domain/contracts/contracts";

import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import ConfirmRedirectDialog from "@/components/dialogs/ConfirmRedirectDialog";

export default {
  name: "FertilityRecordFarmAdd",
  components: {CustomLabel, SimpleTextField, SimpleSelect, ConfirmRedirectDialog},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    producers: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    payload: {},

    fertilityRecordService: null,
    farmService: null,
    contractService: null,

    fertilityIndexes: [],
    payloadIndexes: {},

    producerSelected: false,
    farms: [],
    farmDetails: {},
    grades: ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
  
    showConfirmRedirectDialog: false,
  }),

  methods: {
    async loadFarms(producerId) {
      const loader          = this.$loading.show();
      this.farms            = [];
      this.fertilityIndexes = [];
      this.payloadIndexes   = {};
      this.farmDetails = {};
      this.payload.farm_id = null;

      await this.farmService.listByProducer(producerId).then(result => {
        result.data.map(item => {
          this.farms.push(item);
        });
      });

      await this.contractService
        .enabledIndexesByFertilityAndProducer(producerId)
        .then(result => {
          result.data.map(item => {
            this.fertilityIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
      });

      this.producerSelected = true;
      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};
      
      this.farmService.getFarmDetails(farmId).then(result => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    handleSelect(indexId, indexGrade) {
      this.payloadIndexes[indexId] = indexGrade;
    },

    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    },

    close() {
      this.toggleScrollBar('auto');
      this.$emit("closeDialog");
    },

    closeConfirmRedirectDialog() {
      this.showConfirmRedirectDialog = false;
    },

    validate() {
      if (!this.producerSelected) {
        this.$toasted.show("Selecione um produtor.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      if (Object.keys(this.farmDetails).length === 0) {
        this.$toasted.show("Selecione uma fazenda.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      if (this.$refs.inputHarvest.value === '') {
        this.$toasted.show("O campo safra é obrigatório.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      return true;
    },

    save() {
      if (this.validate()) {
        const loader = this.$loading.show();

        this.payload.harvest = this.farmDetails.harvestYearCalculation;
        this.payload.indexes = this.payloadIndexes;

        this.fertilityRecordService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.close();

            this.$toasted.show("Registro de fertilidade cadastrado com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();

            if (response.status === 409) {
              this.toggleScrollBar('auto');
              this.showConfirmRedirectDialog = true;
            } else {
              const {error} = response.data;

              this.$toasted.show(error, {
                type: "error",
              });
            }
          });
      }
    },

    getAS() {
      const producer = this.farmDetails.producer;
      return producer ? producer.responsible_success_advisor.name : '';
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.toggleScrollBar('hidden');
      }
    }
  },

  beforeMount() {
    this.payload                = FertilityRecords.newData();
    this.fertilityRecordService = FertilityRecords;
    this.farmService            = Farms;
    this.contractService        = Contracts;
  },
};
</script>

<style scoped>
  ::v-deep .col-farm .v-toolbar__content {
    padding: 0;
  }

  ::v-deep .col-index .v-toolbar__content {
    padding: 0;
  }

  .custom-container {
    border: 1px solid #E9D8A6;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }
</style>
